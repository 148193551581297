import React from "react"
import { graphql } from "gatsby"
import Header from "../components/header"
import Footer from "../components/footer"
import Container from "../components/container"
import GraphQLErrorList from "../components/graphql-error-list"
import EventPost from "../components/event-post"
import SEO from "../components/seo"
import Layout from "../containers/layout"

export const query = graphql`
  query EventTemplateQuery($id: String!) {
    event: sanityEvent(id: { eq: $id }) {
      id
      publishedAt
      title
      headline
      introduction
      publishedAt
      slug {
        current
      }
      hideTeams
      roster {
        bluTeam {
          rows {
            _key
            _type
            cells
          }
        }
        redTeam {
          rows {
            _key
            _type
            cells
          }
        }
      }
      _rawBody
    }
  }
`

const EventTemplate = ({ data, errors }) => {
  const event = data && data.event

  return (
    <Layout>
      <Header header={event.headline} subheader={event.introduction} />

      {errors && <SEO title="GraphQL Error" />}
      {event && <SEO title={event.title || "Untitled"} />}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}

      {event && <EventPost {...event} />}
      <Footer />
    </Layout>
  )
}

export default EventTemplate
