import React, { Fragment } from "react"
import BlockContent from "./block-content"
import Container from "./container"

import styles from "./event-post.module.css"

function EventPost({ _rawBody, hideTeams, roster }) {
  return (
    <article className={styles.root}>
      <Container>
        <div className={styles.grid}>
          {!hideTeams ? (
            <div id={styles.teamBlu} className={styles.team}>
              <div className={styles.header}>Player</div>
              <div className={styles.header}>Title</div>

              {roster.bluTeam.rows.map(item => {
                return (
                  <Fragment>
                    <div className={styles.column}>{item.cells[0]}</div>
                    <div className={styles.column}>{item.cells[1]}</div>
                  </Fragment>
                )
              })}
            </div>
          ) : null}
          {!hideTeams ? (
            <div id={styles.teamRed} className={styles.team}>
              <div className={styles.header}>Player</div>
              <div className={styles.header}>Title</div>
              {roster.redTeam.rows.map(item => {
                return (
                  <Fragment>
                    <div className={styles.column}>{item.cells[0]}</div>
                    <div className={styles.column}>{item.cells[1]}</div>
                  </Fragment>
                )
              })}
            </div>
          ) : null}
        </div>
        <div className={styles.container}>
          {_rawBody && <BlockContent blocks={_rawBody} />}
        </div>
      </Container>
    </article>
  )
}

export default EventPost
